<template>
    <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6 mb-8">
        <h1 class="text-3xl font-bold text-center mb-8 text-gray-800">幻友服务协议</h1>

        <div class="text-gray-600 mb-6 leading-relaxed">
            欢迎使用幻友应用程序（以下简称"本应用"）。本协议是用户与幻友应用之间关于使用本应用服务所订立的协议。使用本应用即表示您同意接受本协议的所有条款。请在使用本应用前仔细阅读本协议。
        </div>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">一、定义条款</h2>
            <div class="space-y-3">
                <p class="text-gray-600">1.1 幻友：指由运营方开发和运营的AI虚拟女友交互应用程序。</p>
                <p class="text-gray-600">1.2 用户：指注册、登录、使用本应用的个人用户。</p>
                <p class="text-gray-600">1.3 服务：指幻友向用户提供的AI对话服务。</p>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">二、服务内容</h2>
            <div class="space-y-3">
                <p class="text-gray-600">2.1 本应用主要提供基于人工智能技术的虚拟伴侣对话服务。</p>
                <p class="text-gray-600">2.2 具体服务内容为：</p>
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>与AI虚拟伴侣进行一对一对话交流</li>
                    <li>基于AI技术的情感互动与回应</li>
                    <li>个性化对话体验</li>
                </ul>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">三、用户注册与账号管理</h2>
            <div class="space-y-3">
                <p class="text-gray-600">3.1 用户注册时应当提供真实、准确、完整的个人资料，并在资料发生变更时及时更新。</p>
                <p class="text-gray-600">3.2 用户应妥善保管账号和密码，因账号密码保管不当造成的损失由用户自行承担。</p>
                <p class="text-gray-600">3.3 用户不得将账号出借、转让或出售给他人使用。</p>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">四、用户行为规范</h2>
            <div class="space-y-3">
                <p class="text-gray-600">4.1 用户在使用本应用时应遵守以下规范：</p>
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>遵守国家法律法规</li>
                    <li>不得尝试破解、反编译或反向工程本应用程序</li>
                    <li>不得利用技术手段干扰或破坏应用系统和服务器</li>
                    <li>不得利用本应用进行任何违法或违规活动</li>
                    <li>不得进行任何骚扰、侮辱、诽谤等行为</li>
                </ul>
                <p class="text-gray-600">4.2 用户理解并同意：</p>
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>虚拟伴侣是基于AI技术模拟的程序，不具备真实人格</li>
                    <li>与虚拟伴侣的互动仅供娱乐交流使用</li>
                    <li>不应将虚拟伴侣等同于真实人际关系</li>
                </ul>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">五、系统安全与保护</h2>
            <div class="space-y-3">
                <p class="text-gray-600">5.1 禁止以下危害系统安全的行为：</p>
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>未经授权访问或破解系统</li>
                    <li>干扰或破坏系统正常运行</li>
                    <li>企图绕过系统的安全保护措施</li>
                    <li>使用自动化工具或脚本进行大量访问</li>
                    <li>尝试获取其他用户的个人信息</li>
                    <li>对系统进行任何形式的渗透测试</li>
                    <li>破解、反编译或反向工程应用程序</li>
                    <li>对应用进行二次开发或修改</li>
                </ul>
                <p class="text-gray-600">5.2 如发现任何安全漏洞，请及时向平台报告。恶意利用安全漏洞的行为将承担相应法律责任。</p>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">六、隐私保护</h2>
            <div class="space-y-3">
                <p class="text-gray-600">6.1 我们重视用户隐私保护，承诺：</p>
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>依法收集、使用用户信息</li>
                    <li>采取必要措施保护用户数据安全</li>
                    <li>未经用户同意，不向第三方提供用户个人信息</li>
                </ul>
                <p class="text-gray-600">6.2 详细隐私政策请参见《幻友隐私政策》。</p>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">七、知识产权</h2>
            <div class="space-y-3">
                <p class="text-gray-600">7.1 本应用的所有内容，包括但不限于对话系统、AI模型、程序代码、界面设计等的知识产权均归本公司所有。</p>
                <p class="text-gray-600">7.2 未经本公司书面许可，用户不得以任何方式复制、修改、破解、反编译或使用上述内容。</p>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">八、服务变更、中断或终止</h2>
            <div class="space-y-3">
                <p class="text-gray-600">8.1 本公司保留随时变更、中断或终止服务的权利。</p>
                <p class="text-gray-600">8.2 有下列情形之一的，本公司有权停止或终止向用户提供服务：</p>
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>用户违反本协议相关规定</li>
                    <li>用户提供虚假注册信息</li>
                    <li>用户进行违法或违规行为</li>
                    <li>用户进行破解、攻击等危害系统安全的行为</li>
                    <li>其他损害平台或他人合法权益的行为</li>
                </ul>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">九、免责声明</h2>
            <div class="space-y-3">
                <p class="text-gray-600">9.1 用户理解并同意：</p>
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>本应用不对虚拟伴侣的回复内容准确性做保证</li>
                    <li>不承担因用户使用本应用产生的心理或情感依赖问题</li>
                    <li>不对因网络、设备等问题导致的服务中断承担责任</li>
                </ul>
                <p class="text-gray-600">9.2 本公司不承担因下列情形导致的任何损失：</p>
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>系统维护或升级造成的服务中断</li>
                    <li>因网络运营商原因造成的访问障碍</li>
                    <li>因不可抗力导致的服务中断或终止</li>
                </ul>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">十、协议修改</h2>
            <div class="space-y-3">
                <p class="text-gray-600">10.1 本公司保留随时修改本协议的权利。</p>
                <p class="text-gray-600">10.2 协议修改后，将在应用内显著位置公布修改内容。</p>
                <p class="text-gray-600">10.3 用户继续使用本应用则视为接受修改后的协议。</p>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">十一、法律适用与争议解决</h2>
            <div class="space-y-3">
                <p class="text-gray-600">11.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
                <p class="text-gray-600">11.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向本公司所在地有管辖权的人民法院提起诉讼。</p>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">十二、其他</h2>
            <div class="space-y-3">
                <p class="text-gray-600">12.1 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
                <p class="text-gray-600">12.2 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
            </div>
        </section>
    </div>
</template>