// src/hooks/region.js
import { ref } from 'vue';
import zhCN from '../locales/zh-CN';
import enUS from '../locales/en-US';
import jaJP from '../locales/ja-JP';
import zhTW from '../locales/zh-TW';

const messages = {
  'zh-cn': zhCN,
  'en-us': enUS,
  'ja-jp': jaJP,
  'zh-tw': zhTW,
};

// 初始化 currentRegion 从 localStorage 或 默认地区
const defaultRegion = 'zh-cn';
const currentRegion = ref(localStorage.getItem('region') || defaultRegion);

export function region() {
  const t = (key) => {
    const keys = key.split('.');
    let result = messages[currentRegion.value];

    for (const k of keys) {
      if (result && result[k] !== undefined) {
        result = result[k];
      } else {
        console.warn(`Missing translation for key: ${key}`);
        return key; // 返回键本身作为回退
      }
    }
    return result;
  };

  const setRegion = (lang) => {
    if (messages[lang]) {
      currentRegion.value = lang;
      localStorage.setItem('region', lang);
    }
  };

  return {
    t,
    currentRegion,
    setRegion,
  };
}
