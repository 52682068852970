<!-- src/components/SubscriptionModal.vue -->
<template>
  <div v-if="isOpen" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 p-4">
    <div class="rounded-lg shadow-2xl p-6 max-w-md w-full backdrop-blur-sm bg-white">
      <!-- 关闭按钮 -->
      <button @click="handleClose" class="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
        <x-icon name="x-mark" class="h-6 w-6" />
      </button>

      <!-- 标题和描述 -->
      <h2 class="text-lg font-semibold text-gray-800 mb-4 text-center">
        {{ t('subscription.subscribeTitle') }}
      </h2>
      <p class="text-gray-600 mb-6 text-center">
        {{ t('subscription.subscribeDescription') }}
      </p>

      <!-- 价格和订阅福利 -->
      <div class="mb-6">
        <h3 class="text-md font-medium text-gray-800 mb-2">
          {{ t('subscription.price') }}
        </h3>
        <b class="text-xl bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600">
          $5.00
        </b>
        <span class="text-sm text-gray-500">/{{ t('subscription.monthly') }}</span>
        <h3 class="text-md font-medium text-gray-800 mt-4 mb-2">
          {{ t('subscription.benefits') }}
        </h3>
        <b class="text-lg text-gray-700">
          {{ t('subscription.unlimitedConversations') }}
        </b>
      </div>

      <!-- 订阅按钮 -->
      <button @click="handleSubscribe"
        class="bg-gradient-to-r from-gray-800 to-black px-4 py-2 rounded-lg shadow-xl w-full mb-3 flex items-center justify-center">
        <div class="flex bg-clip-text text-transparent bg-gradient-to-r from-orange-300 to-purple-300 font-bold">
          <ArrowPathIcon v-if="isLoading" class="h-6 w-6 text-gray-400 animate-spin mr-2" />
          {{ t('subscription.subscribeButton') }}
        </div>
      </button>

      <button @click="handleCancel"
        class="text-gray-500 hover:text-gray-700 text-sm w-full flex items-center justify-center transition-colors">
        {{ t('subscription.cancel') }}
      </button>

    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { region } from '../hooks/region';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowPathIcon } from '@heroicons/vue/24/solid';
import { getCookie, record } from '../utils';

defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close', 'subscriptionSuccess']);

const { t } = region();

// 控制是否显示加载动画
const isLoading = ref(false);

const handleCancel = () => {
  emit('close');
};

const handleSubscribe = async () => {
  try {

    record({
      name: 'rumeng_upgrade_click',
      attributes: {
        // method: 'anonymous',
      },
    });

    isLoading.value = true; // Start loading  

    const token = getCookie('chatnext_token'); // 从 Cookie 中读取 token  

    if (!token) {
      console.log('No token found');
      return;
    }

    const response = await fetch('/api/payment/stripe/subscribe', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const session = await response.json();

      let stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);

      const { error } = await stripe.redirectToCheckout({ sessionId: session.sessionId });

      if (error) {
        console.error('Stripe error:', error);
      } else {
        emit('subscriptionSuccess');
      }
    } else {
      console.error('Failed to fetch payment session');
    }
  } catch (error) {
    console.error('Payment initiation failed:', error);
  }
};

</script>

<style scoped>
/* 可根据需要添加样式 */
</style>