<template>
    <div class="min-h-screen bg-hearts bg-gradient-to-b from-pink-50/80 to-white/90">
        <!-- 固定头部导航栏 -->
        <header class="fixed top-0 left-0 right-0 bg-opacity-75 z-50 transition-all duration-300 backdrop-blur-md">
            <div class="max-w-4xl mx-auto">
                <div class="flex items-center justify-between h-14 px-4">
                    <!-- 左侧返回按钮 -->
                    <button @click="goBack"
                        class="text-gray-700 hover:text-gray-900 rounded-full hover:bg-gray-100 transition-colors p-2">
                        <ChevronLeftIcon class="h-5 w-5" />
                    </button>

                    <!-- 中间标题 -->
                    <h2 class="font-semibold text-gray-900">{{ t('info.title') }}</h2>

                    <!-- 右侧编辑和删除按钮：仅当 friendInfo.type === 'customize' 时显示 -->
                    <div class="flex" v-if="friendInfo.type === 'customize'">
                        <button @click="navigateToEdit"
                            class="text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-100 p-2 transition-colors">
                            <PencilIcon class="h-5 w-5" />
                        </button>
                        <button @click="deleteFriend"
                            class="text-red-600 hover:text-red-800 rounded-full hover:bg-red-100 p-2 transition-colors">
                            <TrashIcon class="h-5 w-5" />
                        </button>
                    </div>

                    <!-- 如果 type 不是 customize，则留空位 -->
                    <div v-else class="w-5"></div>
                </div>
            </div>
        </header>

        <!-- 主内容区域 -->
        <main class="pt-14 pb-6 min-h-screen custom-scrollbar" ref="mainContainer">
            <div class="max-w-4xl mx-auto px-4">
                <!-- 用户信息卡片 -->
                <div class="py-6 px-4">
                    <div class="flex items-center space-x-4">
                        <!-- 头像部分 -->
                        <div class="relative inline-block profile-image">
                            <div class="relative">
                                <img :src="friendInfo.avatar_url" :alt="friendInfo.name"
                                    class="w-16 h-16 rounded-full object-cover ring-4 ring-gray-50 shadow-sm">
                                <div
                                    class="absolute -bottom-1 -right-1 w-4 h-4 bg-green-400 rounded-full ring-2 ring-white">
                                </div>
                            </div>
                        </div>

                        <!-- 文字信息部分 -->
                        <div class="flex flex-col items-start">
                            <h1 class="text-xl font-semibold text-gray-900">{{ friendInfo.name }}</h1>
                            <div
                                class="text-sm mt-1 font-medium bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 bg-clip-text text-transparent">
                                {{ friendInfo.bio }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 标签页导航 -->
                <div class="border-b border-gray-200">
                    <div class="flex justify-center -mb-px">
                        <button @click="switchTab('memories')" :class="['px-6 py-3 border-b-2 font-medium text-sm transition-colors',
                            currentTab === 'memories'
                                ? 'border-black text-black'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']">
                            {{ t('info.memories_title') }}
                        </button>
                        <button @click="switchTab('moments')" :class="['px-6 py-3 border-b-2 font-medium text-sm transition-colors',
                            currentTab === 'moments'
                                ? 'border-black text-black'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']">
                            {{ t('info.moments_title') }}
                        </button>
                    </div>
                </div>

                <!-- 标签页内容 -->
                <div class="mt-6">
                    <!-- 记忆标签内容 -->
                    <div v-if="currentTab === 'memories'" class="relative">
                        <!-- 记忆列表 -->
                        <div v-if="sortedMemories.length > 0" class="space-y-4">
                            <div v-for="memory in sortedMemories" :key="memory.id"
                                class="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 group">
                                <div class="p-4">
                                    <div class="flex items-start">
                                        <div class="flex-1">
                                            <p class="text-gray-900 text-sm leading-relaxed break-words">{{
                                                memory.content }}</p>
                                            <p class="mt-2 text-xs text-gray-500">{{ formatDate(memory.date) }}</p>
                                        </div>
                                        <div
                                            class="flex space-x-1 ml-4 opacity-0 group-hover:opacity-100 transition-opacity">
                                            <button @click="editMemory(memory)"
                                                class="p-1.5 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100 transition-colors">
                                                <PencilIcon class="h-4 w-4" />
                                            </button>
                                            <button @click="deleteMemory(memory.id)"
                                                class="p-1.5 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100 transition-colors">
                                                <TrashIcon class="h-4 w-4" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center py-8">
                            <p class="text-gray-500 text-sm">{{ friendInfo.name }} {{ t('info.memories_empty_text') }}
                            </p>
                        </div>

                        <!-- 悬浮添加按钮 -->
                        <button @click="showAddMemoryModal" class="fixed bottom-6 right-6 bg-amber-400/90 text-white w-12 h-12 rounded-full shadow-lg hover:bg-amber-500/90  transition-colors flex items-center justify-center">
                            <PencilIcon class="h-5 w-5" />
                        </button>
                    </div>

                    <!-- 动态标签内容 -->
                    <div v-if="currentTab === 'moments'" class="space-y-4">
                        <div v-if="moments.length > 0">
                            <div v-for="moment in moments" :key="moment.id"
                                class="bg-white rounded-lg shadow-sm overflow-hidden">
                                <div class="p-4">
                                    <p class="text-gray-900 text-sm leading-relaxed break-words">{{ moment.content }}
                                    </p>
                                    <img v-if="moment.image_url" :src="moment.image_url"
                                        class="mt-3 w-full rounded-lg object-cover" alt="Moment image">
                                    <p class="mt-2 text-xs text-gray-500">{{ formatDate(moment.updated_at) }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center py-8">
                            <p class="text-gray-500 text-sm">{{ friendInfo.name }} {{ t('info.moments_empty_text') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <!-- 添加/编辑记忆的弹窗 -->
        <div v-if="showMemoryModal"
            class="fixed inset-0 bg-black/30 backdrop-blur-sm z-[60] flex items-center justify-center"
            @click.self="closeMemoryModal">
            <div
                class="bg-amber-50/95 rounded-2xl shadow-xl max-w-md w-[90%] transform scale-100 animate-in fade-in duration-200 border border-amber-100">
                <!-- 弹窗标题栏 -->
                <div class="px-4 py-3 flex justify-between items-center">
                    <h3 class="text-lg font-medium text-amber-700">
                        {{ editingMemory ? t('info.memories_edit_title') : t('info.memories_add_title') }}
                    </h3>
                    <button @click="closeMemoryModal"
                        class="p-1.5 hover:bg-amber-100/50 rounded-full transition-colors">
                        <XMarkIcon class="h-5 w-5" />
                    </button>
                </div>

                <!-- 弹窗内容区 -->
                <div class="p-4">
                    <textarea v-model="memoryContent"
                        class="w-full bg-white/60 rounded-lg p-4 focus:outline-none focus:ring-2 focus:ring-amber-400 border border-amber-100/50 shadow-sm hover:bg-white/80 transition-colors min-h-[120px] text-amber-900 placeholder-amber-400/80"
                        :placeholder="t('info.memories_input_placeholder')">
            </textarea>
                    <button @click="saveMemory"
                        class="w-full mt-2 px-4 py-2 bg-amber-400/90 text-amber-50 rounded-lg hover:bg-amber-500/90 transition-colors shadow-sm">
                        {{ t('info.save_button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { region } from '../hooks/region'
import { ChevronLeftIcon, PencilIcon, TrashIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { getCookie } from '../utils'

// 路由
const router = useRouter()
const route = useRoute()

// 语言相关
const { t } = region()

// 状态变量
const currentTab = ref('memories')
const showMemoryModal = ref(false)
const memoryContent = ref('')
const editingMemory = ref(null)
const memories = ref([])
const moments = ref([])
const friendInfo = ref({
    friend_id: '',
    name: '',
    avatar_url: '',
    bio: '',
    group_link: '',
    type: ''
})

// 加载状态
const isLoading = ref(false)

// 初始化方法
const init = async () => {
    await getFriendInfo()
    const token = getCookie('chatnext_token') // 从 Cookie 中读取 token
    if (!token) return
    if (currentTab.value === 'memories') {
        await memoriesInit()
    } else {
        await momentsInit()
    }
}

// 切换标签方法
const switchTab = async (tab) => {
    if (currentTab.value === tab || isLoading.value) return

    isLoading.value = true
    currentTab.value = tab

    try {
        if (tab === 'memories') {
            await memoriesInit()
        } else {
            await momentsInit()
        }
    } catch (error) {
        console.error(`Error loading ${tab} data:`, error)
    } finally {
        isLoading.value = false
    }
}

// 获取好友信息
const getFriendInfo = async () => {
    const friend = route.params.friend
    if (!friend) {
        console.error('friend parameter is missing')
        alert('好友信息缺失。')
        router.push({ name: 'Home' })
        return
    }

    const token = getCookie('chatnext_token')
    if (!token) {
        alert('未授权，请重新登录。')
        router.push({ name: 'Home' })
        return
    }

    try {
        const response = await fetch(`/api/friend/get?friend_id=${friend}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (response.ok) {
            const data = await response.json()
            friendInfo.value = data
            document.title = `${friendInfo.value.name}`
        } else {
            const errorData = await response.json()
            throw new Error(errorData.error || '获取朋友信息失败。')
        }
    } catch (error) {
        console.error('Error fetching friend info:', error)
        alert(`获取朋友信息失败：${error.message}`)
        router.push({ name: 'Home' })
    }
}

// 记忆初始化
const memoriesInit = async () => {
    const friendId = route.params.friend

    const token = getCookie('chatnext_token') // 从 Cookie 中读取 token
    if (!token) return

    try {
        const response = await fetch(`/api/memories/get?friend=${friendId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (response.ok) {
            const memoriesData = await response.json()
            memories.value = Array.isArray(memoriesData) ? memoriesData : []
        } else {
            const errorData = await response.json()
            throw new Error(errorData.error || '获取记忆失败。')
        }
    } catch (error) {
        console.error('Error refreshing memories:', error)
        alert(`获取记忆失败：${error.message}`)
    }
}

// 动态初始化
const momentsInit = async () => {
    const friendId = route.params.friend
    const token = getCookie('chatnext_token') // 从 Cookie 中读取 token
    if (!token) return

    try {
        const response = await fetch(`/api/moments/get?friend=${friendId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (response.ok) {
            const data = await response.json()
            moments.value = data
        } else {
            const errorData = await response.json()
            throw new Error(errorData.error || '获取动态失败。')
        }
    } catch (error) {
        console.error('获取动态时发生错误:', error)
        alert(`获取动态失败：${error.message}`)
        moments.value = []
    }
}

// 显示添加记忆弹窗
const showAddMemoryModal = () => {
    editingMemory.value = null
    memoryContent.value = ''
    showMemoryModal.value = true
}

// 编辑记忆
const editMemory = (memory) => {
    editingMemory.value = memory
    memoryContent.value = memory.content
    showMemoryModal.value = true
}

// 关闭记忆弹窗
const closeMemoryModal = () => {
    showMemoryModal.value = false
    memoryContent.value = ''
    editingMemory.value = null
}

// 保存记忆（新增或更新）
const saveMemory = async () => {
    if (!memoryContent.value.trim()) return

    const token = getCookie('chatnext_token') // 从 Cookie 中读取 token
    if (!token) {
        alert('未授权，请重新登录。')
        router.push({ name: 'Home' })
        return
    }

    const friendId = route.params.friend

    try {
        if (editingMemory.value) {
            // 更新记忆
            const response = await fetch('/api/memories/update', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    friend: friendId,
                    memory_id: editingMemory.value.id,
                    content: memoryContent.value.trim()
                }),
            })

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.error || '更新记忆失败。')
            }
        } else {
            // 添加新记忆
            const response = await fetch('/api/memories/add', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    friend: friendId,
                    content: memoryContent.value.trim()
                }),
            })

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.error || '添加记忆失败。')
            }
        }

        // 刷新记忆列表
        await memoriesInit()
        closeMemoryModal()

    } catch (error) {
        console.error('Error saving memory:', error)
        alert(`保存记忆失败：${error.message}`)
    }
}

// 删除记忆
const deleteMemory = async (memoryId) => {
    if (!confirm('确定要删除这条记忆吗？')) return

    const token = getCookie('chatnext_token') // 从 Cookie 中读取 token
    if (!token) {
        alert('未授权，请重新登录。')
        router.push({ name: 'Home' })
        return
    }

    const friendId = route.params.friend

    try {
        const response = await fetch('/api/memories/delete', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                friend: friendId,
                memory_id: memoryId
            }),
        })

        if (response.ok) {
            alert('记忆已成功删除。')
            await memoriesInit()
        } else {
            const errorData = await response.json()
            throw new Error(errorData.error || '删除记忆失败。')
        }
    } catch (error) {
        console.error('Error deleting memory:', error)
        alert(`删除记忆失败：${error.message}`)
    }
}

// 删除朋友
const deleteFriend = async () => {
    if (!confirm('确定要删除这个朋友吗？此操作无法撤销。')) return

    const token = getCookie('chatnext_token') // 从 Cookie 中读取 token
    if (!token) {
        alert('未授权，请重新登录。')
        router.push({ name: 'Home' })
        return
    }

    try {
        const response = await fetch('/api/friend/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                friend_id: friendInfo.value.friend_id
            })
        })

        if (response.ok) {
            alert('朋友已成功删除。')
            router.push({ name: 'Home' })
        } else {
            const errorData = await response.json()
            throw new Error(errorData.error || '删除朋友失败。')
        }
    } catch (error) {
        console.error('Error deleting friend:', error)
        alert(`删除朋友失败：${error.message}`)
    }
}

// 格式化日期
const formatDate = (dateString) => {
    const date = new Date(dateString)
    const now = new Date()
    const diff = now - date
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))

    if (days === 0) {
        return '今天'
    } else if (days === 1) {
        return '昨天'
    } else if (days < 7) {
        return `${days}天前`
    } else {
        return date.toLocaleString('zh-CN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        })
    }
}

// 返回到聊天页面
const goBack = () => {
    router.push({
        name: 'Chat',
        params: { friend: friendInfo.value.friend_id }
    })
}

// 排序后的记忆列表
const sortedMemories = computed(() => {
    return [...memories.value].sort((a, b) => new Date(b.date) - new Date(a.date))
})

// 导航到编辑页面
const navigateToEdit = () => {
    router.push({
        name: 'Edit',
        params: { friend: friendInfo.value.friend_id }
    })
}

// 生命周期钩子
onMounted(() => {
    init()
})
</script>

<style scoped>
/* 您可以根据需要添加或修改样式 */
</style>
