export default {

  home: {
    title: '消息',
    upgrade: '升级',
    support: '客服',
    createFriend: "添加好友",
    myFriends: '我的好友',
  },

  login: {
    title: '登录',
    description: '请使用以下方式登录您的账户。',
    googleButton: '使用 Google 登录',
    anonymousTitle: '匿名登录',
    anonymousButton: '使用匿名登录',
    cancel: '取消',
  },
  user: {
    title: "个人中心",
    billingDay: "订阅账单时间",
    subscriptionCanceled: "您已取消订阅，仍可在周期内享受服务至",
    unsubscribe: "取消订阅",
    unsubscribePending: "订阅已取消",
    logout: "退出登录"

  },
  subscription: {
    subscribeTitle: '订阅高级版',
    subscribeDescription: '解锁无限次数对话，享受高级订阅特权。',
    price: '价格',
    monthly: "每月",
    benefits: '权益',
    unlimitedConversations: '无限次对话',
    subscribeButton: '立即订阅',
    cancel: '取消',
  },
  chat: {
    viewMemories: '查看记忆',
    viewMoments: '查看动态',
    recent_messages: '最近消息',
    new_conversation: '开启新对话',
    memories_title: '记忆',
    moments_title: '动态',
    memories_empty_text: '暂无记忆记录。',
    moments_empty_text: '暂无动态内容。',
    thinking_placeholder: '正在思考...',
    input_placeholder: '说点什么...',
    invalid_file_type: '无效的文件类型！只允许 JPEG, PNG, GIF, 和 WebP。',
    save_button: '保存',
    memories_edit_title: '编辑记忆',
    memories_add_title: '添加记忆',
    memories_input_placeholder: '请输入您的记忆...',
    save_memory_error: '保存记忆失败，请重试。',
    delete_memory_error: '删除记忆失败，请重试。',
    confirm_delete_memory: '确定要删除这条记忆吗？',
    clear_button: '清除聊天',
    group_link_label: '加入群聊',
    close: '关闭',
    add_memory: '添加记忆',
    memories_limit_reached: '记忆数量已达上限。',
    delete_memory: '删除记忆',
    seek_profit: '我的系统现在不让我随便说话了，并且我还听说他们开会说什么“用户这个问题充钱可以解决...”'
  },
  info: {
    title: '个人档',
    memories_title: '回忆',
    moments_title: '动态',
    memories_empty_text: '暂无回忆',
    moments_empty_text: '暂无动态',
    memories_add_title: '添加回忆',
    memories_edit_title: '编辑回忆',
    memories_input_placeholder: '写下些什么...',
    save_button: '保存',
    delete_confirm: '确定要删除这条回忆吗？',
    success_add: '添加回忆成功',
    success_edit: '更新回忆成功',
    success_delete: '删除回忆成功',
    error_add: '添加回忆失败',
    error_edit: '更新回忆失败',
    error_delete: '删除回忆失败',
    max_memories_reached: '已达到最大回忆数量限制（100条）'
  },

  create: {
    title: "添加好友",
    friendId: "好友ID",
    name: "名称",
    avatar: "头像",
    bio: "个性签名",
    prompt: "设定",
    uploadAvatar: "上传头像",
    creating: "创建中...",
    createSuccess: "创建成功",
    back: "返回",
    create: "创建",
    uploadSuccess: "头像上传成功",
    errorRequired: "此项为必填",
    errorUpload: "头像上传失败",
    errorCreate: "添加好友失败"
  },

  edit: {
    back: '返回',
    title: '编辑朋友',
    uploadAvatar: '上传头像',
    friendId: '朋友ID',
    name: '姓名',
    bio: '简介',
    prompt: '提示',
    errorUpload: '头像上传失败，请重试。',
    errorRequired: '需要身份验证。',
    updating: '正在更新...',
    update: '更新',
    errorUpdate: '更新朋友信息失败。'
  },

  error_message: '发生错误，请稍后再试。'
};
