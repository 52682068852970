// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css'
import router from './router';
import { region } from './hooks/region';
const { setRegion } = region();
const app = createApp(App);

// 在应用初始化时处理地区参数
router.beforeEach((to, from, next) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const regionParam = urlSearchParams.get('region');

  // console.log(regionParam)

  const defaultRegion = 'zh-cn';

  if (regionParam) {
    // 设置到 localStorage
    localStorage.setItem('region', regionParam);

    setRegion(regionParam);

    // 移除查询参数，保持 URL 整洁
    // urlSearchParams.delete('region');
    // const newUrl = `${window.location.pathname}${urlSearchParams.toString() ? '?' + urlSearchParams.toString() : ''}`;
    // window.history.replaceState({}, '', newUrl);

  } else {
    // 如果 localStorage 中没有地区信息，设置默认地区
    if (!localStorage.getItem('region')) {
      localStorage.setItem('region', defaultRegion);
      const { setRegion } = region();
      setRegion(defaultRegion);
    }
    
  }

  next();
});

app.use(router);
app.mount('#app');
