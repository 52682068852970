<template>
    <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
        <h1 class="text-3xl font-bold text-center mb-8 text-gray-800">幻友隐私政策</h1>

        <div class="text-gray-600 mb-6 leading-relaxed">
            本隐私政策说明我们如何收集、使用、保护和共享您的个人信息。我们深知个人信息对您的重要性，并会尽最大努力保护您的个人信息安全。
        </div>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">一、信息收集</h2>
            <div class="space-y-4">
                <div>
                    <p class="text-gray-700 font-medium mb-2">1.1 我们收集的信息</p>
                    <div class="space-y-4 pl-4">
                        <div>
                            <p class="text-gray-700 mb-2">A. 您提供的信息</p>
                            <ul class="list-disc pl-8 text-gray-600 space-y-2">
                                <li>注册信息（用户名、密码、手机号码等）</li>
                                <li>个人设置信息（头像、个性化设置等）</li>
                                <li>对话内容</li>
                            </ul>
                        </div>
                        <div>
                            <p class="text-gray-700 mb-2">B. 自动收集的信息</p>
                            <ul class="list-disc pl-8 text-gray-600 space-y-2">
                                <li>设备信息（设备型号、操作系统版本、设备设置）</li>
                                <li>日志信息（使用时间、使用时长、错误日志）</li>
                                <li>位置信息（如果您授权）</li>
                                <li>网络状态信息</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <p class="text-gray-700 font-medium mb-2">1.2 我们不收集的信息</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>银行账户信息</li>
                        <li>身份证信息</li>
                        <li>生物识别信息</li>
                        <li>精确GPS定位信息</li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">二、信息使用</h2>
            <div class="space-y-4">
                <div>
                    <p class="text-gray-700 font-medium mb-2">2.1 我们将收集的信息用于：</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>提供AI对话服务</li>
                        <li>优化用户体验</li>
                        <li>系统维护和故障排除</li>
                        <li>安全防护</li>
                        <li>数据分析和产品改进</li>
                    </ul>
                </div>
                <div>
                    <p class="text-gray-700 font-medium mb-2">2.2 AI训练</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>您的对话内容可能会用于改进AI模型</li>
                        <li>使用前会进行匿名化处理</li>
                        <li>您可以选择关闭此功能</li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">三、信息存储</h2>
            <div class="space-y-4">
                <div>
                    <p class="text-gray-700 font-medium mb-2">3.1 存储位置</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>信息存储在中国境内的服务器</li>
                        <li>采用业界标准的加密技术</li>
                        <li>定期备份以防数据丢失</li>
                    </ul>
                </div>
                <div>
                    <p class="text-gray-700 font-medium mb-2">3.2 存储期限</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>账号处于活跃状态时持续保存</li>
                        <li>账号注销后30天内完全删除</li>
                        <li>法律法规要求的特殊情况除外</li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">四、信息安全</h2>
            <div class="space-y-4">
                <div>
                    <p class="text-gray-700 font-medium mb-2">4.1 安全措施</p>
                    <p class="text-gray-600 mb-2">我们采取以下措施保护您的信息：</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>数据加密存储</li>
                        <li>访问权限控制</li>
                        <li>安全审计</li>
                        <li>应急响应机制</li>
                        <li>定期安全评估</li>
                    </ul>
                </div>
                <div>
                    <p class="text-gray-700 font-medium mb-2">4.2 安全事件处理</p>
                    <p class="text-gray-600 mb-2">如发生安全事件，我们将：</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>第一时间采取补救措施</li>
                        <li>及时通知受影响的用户</li>
                        <li>按要求向监管部门报告</li>
                        <li>配合调查和处理</li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">五、信息共享</h2>
            <div class="space-y-4">
                <div>
                    <p class="text-gray-700 font-medium mb-2">5.1 信息共享原则</p>
                    <p class="text-gray-600 mb-2">我们不会与任何第三方分享您的个人信息，除非：</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>获得您的明确同意</li>
                        <li>法律法规要求</li>
                        <li>司法机关要求</li>
                        <li>保护其他用户或公众的合法权益所必需</li>
                    </ul>
                </div>
                <div>
                    <p class="text-gray-700 font-medium mb-2">5.2 业务合作</p>
                    <p class="text-gray-600 mb-2">如需要与合作伙伴共享信息，我们会：</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>进行匿名化处理</li>
                        <li>签署严格的保密协议</li>
                        <li>确保数据使用符合本政策</li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">六、用户权利</h2>
            <div class="space-y-4">
                <div>
                    <p class="text-gray-700 font-medium mb-2">6.1 您享有以下权利：</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>访问、更正您的个人信息</li>
                        <li>删除对话记录</li>
                        <li>注销账号</li>
                        <li>撤回同意</li>
                        <li>投诉举报</li>
                    </ul>
                </div>
                <div>
                    <p class="text-gray-700 font-medium mb-2">6.2 权利行使方式</p>
                    <ul class="list-disc pl-8 text-gray-600 space-y-2">
                        <li>在应用内直接操作</li>
                        <li>联系客服请求协助</li>
                        <li>通过官方邮箱提交申请</li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">七、未成年人保护</h2>
            <div class="space-y-4">
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>若您是未满18周岁的未成年人，请在监护人陪同下阅读本政策。</li>
                    <li>未成年人使用本应用需要获得监护人的同意。</li>
                    <li>我们会采取特殊措施保护未成年人的个人信息。</li>
                </ul>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">八、政策更新</h2>
            <div class="space-y-4">
                <ul class="list-disc pl-8 text-gray-600 space-y-2">
                    <li>我们可能会适时更新本隐私政策。</li>
                    <li>政策更新后，我们会在应用内显著位置发布通知。</li>
                    <li>继续使用本应用即表示您同意更新后的隐私政策。</li>
                </ul>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">九、联系我们</h2>
            <div class="space-y-4">
                <p class="text-gray-600">如您对本隐私政策有任何疑问、意见或建议，可以通过以下方式与我们联系：</p>
                <ul class="list-none pl-8 text-gray-600 space-y-2">
                    <li>电子邮箱：contact@chatnext.ai</li>
                </ul>
                <p class="text-gray-600">我们会在收到您的询问后尽快回复。</p>
            </div>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4 text-gray-800">十、其他</h2>
            <div class="space-y-4">
                <p class="text-gray-600">10.1 本政策与《幻友服务协议》具有同等法律效力。</p>
                <p class="text-gray-600">10.2 本政策中未尽事宜，适用《幻友服务协议》的相关约定。</p>
            </div>
        </section>
    </div>
</template>