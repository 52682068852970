// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Chat from '../views/Chat.vue';
import Info from '../views/Info.vue';
import User from '../views/User.vue';
import Terms from '../views/Terms.vue';
import Privacy from '../views/Privacy.vue';
import Create from '../views/Create.vue';
import Edit from '../views/Edit.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/chat/:friend',
    name: 'Chat',
    component: Chat,
    props: true,
  },
  {
    path: '/info/:friend',
    name: 'Info',
    component: Info,
    props: true,
  },
  {
    path: '/user',
    name: 'User',
    component: User,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/create',
    name: 'Create',
    component: Create,
  },
  {
    path: '/edit/:friend',
    name: 'Edit',
    component: Edit,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
