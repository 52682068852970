export const getCookie = (name) => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key === name) {
            return decodeURIComponent(value);
        }
    }
    return null;
};

import { ClickstreamAnalytics, SendMode } from "@aws/clickstream-web";

(() => {
    // 初始化亚马逊数据上报
    ClickstreamAnalytics.init({
        appId: 'sider_web',
        endpoint: 'https://event.sider.ai/collect',
        sendMode: SendMode.Batch,
        sendEventsInterval: 1000,
        isTrackPageViewEvents: false,
        isTrackUserEngagementEvents: false,
        isTrackClickEvents: false,
        isTrackScrollEvents: false,
        isTrackSearchEvents: false,
    })
})();


// 获取用户信息
function eventUserInfo() {
    return {
        user_source: null,
        user_p1: null,
        user_p2: null,
        user_country: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
}

// 获取全局属性
function eventGlobalAtt() {
    return {
        platform: 'Web',
        app_version: '1.0',
        product_name: 'rumeng',
        system_language: navigator.language || null,
    };
}

// 记录事件
export function record({ name, attributes }) {
    ClickstreamAnalytics.record({
        name,
        attributes: {
            ...attributes,
            ...eventUserInfo(),
            ...eventGlobalAtt(),
        },
    });
}

export const gtag = function (event, action, params) {
    if (window.gtag) {
        window.gtag(event, action, params);
    }
}

export const reportConversion = function (url) {
    if (window.gtag_report_conversion) {
        return window.gtag_report_conversion(url);
    }
    return false;
}
